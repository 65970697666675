import React, { useState } from "react";
import "./App.css";
import IBANInput from "./components/frontend/input/IBANInput";
import SubmitButton from "./components/frontend/submit/SubmitButton";
import Popup from "./components/frontend/popup/Popup";
import {validateIBAN} from "./components/restapi/IBANValidatorService";

const App: React.FC = () => {
  const [iban, setIban] = useState<string>("");
  const [response, setResponse] = useState<{ verified: boolean; bank?: string; error?: string } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

   const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResponse(null);

    const sanitizedIban = iban.replace(/\s+/g, "");

    try {
      const result = await validateIBAN(sanitizedIban);
      setResponse(result);
    } catch (err: any) {
      setError("Ein Fehler ist aufgetreten. Bitte versuche es erneut.");
    } finally {
      setLoading(false);
    }
  };

  const closePopup = () => {
    setResponse(null);
    setError(null);
  };

  return (
    <div className="app-container">
      <h1>IBAN Validator</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <IBANInput iban={iban} setIban={setIban} />
        <SubmitButton loading={loading} onClick={handleSubmit} />
      </form>
      <Popup response={response} error={error} closePopup={closePopup} />
    </div>
  );
};

export default App;
