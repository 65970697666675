import React from "react";
import "./IBANInput.css";

interface IBANInputProps {
  iban: string;
  setIban: (iban: string) => void;
}

const IBANInput: React.FC<IBANInputProps> = ({ iban, setIban }) => {
  const formatIban = (value: string): string => {
    return value.replace(/\s+/g, "").replace(/(.{4})/g, "$1 ").trim();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = formatIban(rawValue);
    setIban(formattedValue);
  };

  return (
    <div className="iban-input-container">
      <input
        type="text"
        value={iban}
        onChange={handleChange}
        placeholder="IBAN eingeben"
        className="iban-input"
        required
      />
    </div>
  );
};

export default IBANInput;
