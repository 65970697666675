import React from "react";
import "./Popup.css";

interface PopupProps {
  response: { verified: boolean; bank?: string; error?: string } | null;
  error: string | null;
  closePopup: () => void;
}

const Popup: React.FC<PopupProps> = ({ response, error, closePopup }) => {
  const handleClose = () => {
    closePopup();
  };

  if (!response && !error) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h2 style={response?.verified? {color:"green"}:{color:"red"}}>{response?.verified ? "IBAN gültig" : "IBAN ungültig"}</h2>
        {response?.verified && <p>Bank: {response.bank}</p>}
        {!response?.verified && <p className="error-message">Fehler: {response?.error != null? response?.error:"Fehler beim Verbinden!"}</p>}
        <button onClick={handleClose} className="close-button">
          Schließen
        </button>
      </div>
    </div>
  );
};

export default Popup;
