import React from "react";
import "./SubmitButton.css";

interface SubmitButtonProps {
  loading: boolean;
  onClick: (e: React.FormEvent) => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ loading, onClick }) => {
  const handleClick = (e: React.FormEvent) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <button
      type="submit"
      onClick={handleClick}
      disabled={loading}
      className="submit-button"
    >
      {loading ? "Validiere..." : "Validieren"}
    </button>
  );
};

export default SubmitButton;
