export interface ValidatorResponse {
  verified: boolean;
  bank?: string;
  error?: string;
}

const BASE_URL = "http://185.135.158.50:8081";

export const validateIBAN = async (iban: string): Promise<ValidatorResponse> => {
  try {
    const response = await fetch(`${BASE_URL}/validator?iban=${encodeURIComponent(iban)}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data: ValidatorResponse = await response.json();
    return data;
  } catch (error) {
    console.error("Error validating IBAN:", error);
    throw error;
  }
};
